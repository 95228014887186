<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування новини" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Header"
                  v-model="intro.header"
                  :messages="['The recommended number of characters is 15']"
                />
                <!--                <va-input-->
                <!--                  label="Subheader"-->
                <!--                  v-model="intro.subheader"-->
                <!--                  :messages="['The recommended number of characters is 15']"-->
                <!--                />-->
                <!--                <va-input-->
                <!--                  label="Desc"-->
                <!--                  v-model="intro.desc"-->
                <!--                  :messages="['The recommended number of characters is 15']"-->
                <!--                />-->
                <va-card class="mb-3" title="Image *">
                  <div v-if="imgExisting">
                    <a style="display: block;" target="_blank" :href="imgExisting"><img style="max-width: 400px;" :src="imgExisting"></a>
                    <div><va-button small color="danger" @click="imgExisting = deleteImage(imgId) ? '' : imgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="img"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage(img[0])">Завантажити</va-button>
                </va-card>
                <!--                <va-input-->
                <!--                  label="Link name"-->
                <!--                  v-model="intro.link_name"-->
                <!--                  :messages="['The recommended number of characters is 15']"-->
                <!--                />-->
                <!--                <va-input-->
                <!--                  label="Link href"-->
                <!--                  v-model="intro.link_href"-->
                <!--                  :messages="['The recommended number of characters is 15']"-->
                <!--                />-->
                <va-input
                  label="trailingText"
                  v-model="intro.trailingText"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
// import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'
import Vue from 'vue'

export default {
  name: 'grid',
  components: {
    // ckeditor: CKEditor.component,
  },
  data () {
    return {
      intro: {
        desc: null,
        header: null,
        img: null,
        link_href: null,
        link_name: null,
        subheader: null,
      },
      history: null,
      missions: null,
      principles: null,

      header: '',
      subheader: '',
      desc: '',
      link_name: '',
      link_href: '',
      text_contents_text: '',
      trailingText: '',
      stats_text: '',
      numList: null,
      quotes: null,
      stats: null,
      companyContents: null,

      img: [],
      imgId: '',
      imgNew: '',
      imgExisting: '',
      minicardImg: [],
      minicardImgId: '',
      minicardImgExisting: '',
      label: '',
      alias: '',
      published_at: '08-05-2020 14:10:00',
      content: '',
      position: '',
      published: false,

      seoId: '',
      seoTitle: '',
      seoDescription: '',
      seoKeywords: '',
      seoText: '',
      seoImage: [],
      seoImageId: '',
      seoImageExisting: '',
      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_MS_DEV_API_URL}/uk/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      sign: '',

      errors: [],

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    if (!this.$attrs.id) {
      const today = new Date()
      this.published_at = today.toLocaleDateString('uk-UA') + ' ' + today.toLocaleTimeString('uk-UA')
      const generateRandomString = (length = 6) => Math.random().toString(20).substr(2, length)
      this.sign = generateRandomString(32)
    }
    this.fetch()
  },
  methods: {
    uploadImage (file) {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('file', file)
      axios.post(`${process.env.VUE_APP_MS_DEV_API_URL}/${this.locale}/admin/media`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.imgNew = response.data.patch
          this.imgExisting = `${process.env.VUE_APP_MS_DEV_URL}/` + this.imgNew
          this.imgId = response.data.id
          this.intro.img = response.data.patch
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id) {
      return axios.delete(`${process.env.VUE_APP_MS_DEV_API_URL}/${this.locale}/admin/media/${id}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
        // .catch(() => {
        //   this.showToast('Error')
        // })
    },
    submit () {
      const data = {
        intro: this.intro,
      }

      axios.put(`${process.env.VUE_APP_MS_DEV_API_URL}/${this.locale}/admin/development`, data)
        .then(response => {
          this.$router.push({ name: 'ms-page-development' })
          this.showToast('Success')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          console.log(error)
          this.showToast('Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_MS_DEV_API_URL}/${this.locale}/admin/development`)
        .then(response => {
          this.intro = response.data.intro
          this.imgNew = response.data.intro.img
          this.imgExisting = `${process.env.VUE_APP_MS_DEV_URL}/` + response.data.intro.img
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
  },
  computed: {
    tabFields () {
      return [
        {
          name: 'key',
          title: 'Key',
          width: '40%',
        },
        {
          name: 'content',
          title: 'Content',
          width: '40%',
        },
        {
          title: 'Опубліковано',
          name: '__slot:toggle',
          width: '20%',
        },
        {
          title: 'Переклади',
          name: '__slot:translations',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        }]
    },
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
